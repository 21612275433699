import React from "react";
import images from "../assets/images/index";
import styles from "./style/team.module.css";
import Typist from "react-typist";

const Team = () => {
  return (
    <div className={styles.mainDiv}>
      <Typist cursor={{ show: false }}>
        <h1>Our Team</h1>
      </Typist>
      <div className={styles.subTitle}>
        <h5>
          FIND YOUR FUTURE EDUCATIONAL DESTINATION WITH THE HELP OF OUR TEAM
        </h5>
        <h6>
          We have a team of dedicated staff willing to assist in every deserved
          way
        </h6>
      </div>
      <div className={styles.sectionWrapper}>
        <section className={styles.section}>
          <img
            src={images.common.david}
            alt="david"
            className={styles.profileImage}
          />

          <div className={styles.profileText}>
            <h5>David Ebubechukwu Nwobodo</h5>
            <h6>CEO Study Diamond Ltd.</h6>
          </div>
        </section>
        <section className={styles.section}>
          <img
            id={styles.laleh}
            src={images.common.laleh}
            alt="laleh"
            className={styles.profileImage}
          />
          <div className={styles.profileText}>
            <h5>Dr. Laleh Mousavi</h5>
            <h6>Co-Founder and General Manager</h6>
          </div>
        </section>
            
            
        <section className={styles.section}>
          <img
            src={images.common.barrister}
            alt="Barrister"
            className={styles.profileImage}
          />
          <div className={styles.profileText}>
            <h5>Barrister Susan Okorie</h5>
            <h6>Legal Representative - Nigeria</h6>
          </div>
        </section>

        <section className={styles.section}>
          <img
            src={images.common.taraneh}
            alt="Taraneh"
            className={styles.profileImage}
            id={styles.profileImageTaraneh}
          />
          <div className={styles.profileText}>
            <h5>Taraneh Mousavi</h5>
            <h6> Head of Admission Team </h6>
          </div>
        </section>

           <section className={styles.section}>
          <img
            src={images.common.ramin}
            alt="ramin"
            className={styles.profileImage}
          />
          <div className={styles.profileText}>
            <h5>Ramin Afshoon</h5>
            <h6>Head of Visa Team</h6>
          </div>
        </section>
            
               <section className={styles.section}>
          <img
            src={images.common.mitra}
            alt="mitra"
            className={styles.profileImage}
          />
          <div className={styles.profileText}>
            <h5>Mitra Mohammadian</h5>
            <h6>Supervisor of Visa Team</h6>
          </div>
        </section>

        <section className={styles.section}>
          <img
            src={images.common.amanda}
            alt="amanda"
            className={styles.profileImage}
          />
          <div className={styles.profileText}>
            <h5>Egbele Amanda Adesua</h5>
            <h6>Administrative Head - Nigeria</h6>
          </div>
        </section>

   <section className={styles.section}>
          <img
            src={images.common.reza  }
            alt="reza"
            className={styles.profileImage}
          />
          <div className={styles.profileText}>
            <h5>Reza Mousavi </h5>
            <h6>Administrative Head - Middle East</h6>
          </div>
        </section>


            
            <section className={styles.section}>
          <img
            src={images.common.ada}
            alt="ada"
            className={styles.profileImage}
          />
          <div className={styles.profileText}>
            <h5>Adana Beatrice Edeh</h5>
              <h6> Admission Officer</h6>
          </div>
        </section>

            
        <section className={styles.section}>
          <img
            src={images.common.paulAweleNti  }
            alt="paul Awele Nti"
            className={styles.profileImage}
          />
          <div className={styles.profileText}>
            <h5>Paul Awele Nti </h5>
            <h6>Marketing Manager</h6>
          </div>
        </section>

    
            
         <section className={styles.section}>
          <img
            src={images.common.bahador  }
            alt="bahador"
            className={styles.profileImage}
          />
          <div className={styles.profileText}>
            <h5>Bahador Amani </h5>
            <h6>SOP Advisor</h6>
          </div>
        </section>
            

       <section className={styles.section}>
          <img
            src={images.common.farzam  }
            alt="farzam"
            className={styles.profileImage}
          />
          <div className={styles.profileText}>
            <h5>Farzam Ghorbani </h5>
            <h6>SOP Advisor</h6>
          </div>
        </section>


            
        <section className={styles.section}>
          <img
            src={images.common.simin  }
            alt="simin"
            className={styles.profileImage}
          />
          <div className={styles.profileText}>
            <h5>Simin Zandi </h5>
            <h6>SOP Advisor</h6>
          </div>
        </section>

            
        <section className={styles.section}>
          <img
            src={images.common.mahdi }
            alt="mahdi"
            className={styles.profileImage}
          />
          <div className={styles.profileText}>
            <h5>Mahdi Hamedi </h5>
            <h6>SOP Advisor</h6>
          </div>
        </section>

            
      </div>
    </div>
  );
};

export default Team;
